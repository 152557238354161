.root {
	border-radius: 48px;
	width: fit-content;
	height: fit-content;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	&[data-fullwidth="true"] {
		width: 100%;
	}

	&[data-theme="primary"] {
		background-color: var(--color-primary-blue-green);
		border: 1px solid var(--color-primary-blue-green);
	}

	&[data-theme="empty"] {
		background-color: var(--color-generic-white);
		border: 1px solid var(--color-primary-blue-green);
	}

	&[data-size="small"] {
		padding: 12px 24px;
	}

	&[data-size="medium"] {
		padding: 18px 58px;
	}

	.left-part {
		display: flex;
		align-items: center;
		white-space: nowrap;
	}

	.right-part {
		display: flex;
		align-items: center;
		&:before {
			display: inline-block;
			width: 1px;
			height: 24px;
			margin: 0 24px;
			background: white;
			content: "";
		}
	}
}

.root {
    display: flex;
    gap: 16px;
    position: relative;
    width: fit-content;
    .active-bar {
        height: 1px;
        background-color: black;
        position: absolute;
        top: 24px;
        transition: 250ms ease-in-out;
    }
}

.root {
	position: fixed;
	z-index: 2;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	label {
		height: 100%;
	}
}

.root {
    position: relative;
    .input {
        width: 100%;
        background-color: var(--color-neutral-grey-soft);
        border: 1px solid transparent;

        border-radius: 48px;

        font-family: Roboto;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.36px;

        padding: 16px 16px 16px 48px;
        &:focus {
            outline: none;
            border: 1px solid var(--color-neutral-grey-strong);
        }

        &::placeholder {
            color: black;
            opacity: 0.3;
        }
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        opacity: 0.3;
    }
}

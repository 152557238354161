.root {
	.images {
		display: flex;
		max-width: 1024px;
		overflow-x: hidden;

		.image-container {
			min-width: 100vw;
			display: flex;
			justify-content: center;

			@media (min-width: 1023px) {
				min-width: 800px;
				max-width: 800px;
			}
			.image {
				object-fit: contain;
				width: 100vw;
				height: 45vw;
				min-width: 100vw;

				@media (min-width: 1023px) {
					min-width: 400px;
					max-width: 400px;
					height: auto;
					max-height: 350px;
				}
			}
		}
	}

	.dots-container {
		display: flex;
		gap: 24px;
		justify-content: center;
		margin-top: 16px;
		.dot {
			width: 10px;
			height: 10px;
			background-color: var(--color-neutral-grey-strong);
			border-radius: 360px;
			transition: all 250ms ease-in-out;
			&[data-active="true"] {
				background-color: var(--color-primary-blue-green);
				width: 21px;
			}
		}
	}
}

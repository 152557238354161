.root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	padding-bottom: 100px;

	max-width: 1024px;
	margin: auto;
	overflow-x: hidden;
	.separator {
		height: 1px;
		width: 100%;
		background-color: var(--color-primary-blue-green);
	}

	.separator-prices {
		height: 1px;
		width: 100%;
		background-color: var(--color-primary-blue-green);
		margin-top: 16px;
	}

	.top-container {
		box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.09);
		padding: 16px;

		.total-container {
			display: flex;
			flex-direction: column;
			gap: 8px;
			margin-top: 16px;
			.line {
				display: flex;
				justify-content: space-between;
				align-items: flex-end;
			}
		}
	}

	.content-container {
		flex: 1;
		display: flex;
		.empty-container {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 16px;
			align-items: center;
			text-align: center;
			padding: 0 48px;
			padding-top: 7vh;
			position: relative;
			.button-scan {
				left: 16px;
				right: 16px;
				position: fixed;
				bottom: 100px;
			}
		}

		.items-container {
			display: flex;
			flex-direction: column;
			margin-top: 32px;
			margin-bottom: 68px;
			padding: 0 16px;
			gap: 24px;
			width: 100%;

			.item {
				display: flex;
				flex-direction: column;
				gap: 24px;
			}
		}
	}

	.button-container {
		position: fixed;
		left: 16px;
		right: 16px;
		bottom: 92px;
	}
}

@keyframes point-animation {
	0% {
		transform: scale(0.5);
	}
	50% {
		transform: scale(1);
	}
	100% {
		opacity: 0;
	}
}

.root {
	position: absolute;

	/* Point container styling */
	.point-container {
		position: relative;
		top: -30px;
		left: -30px;
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		/* Point styling */
		.point {
			border-radius: 50%;
			width: 24px;
			height: 24px;
			// background-color: rgba(255, 255, 255, 0.4);
			background-color: var(--color-primary-blue-green);
			display: flex;
			justify-content: center;
			align-items: center;
			animation: inner-point-animation infinite 1s ease-in-out;
			.point-inner {
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
				animation: point-animation infinite 2s ease-in-out;
				position: absolute;
				border-radius: 50%;
				width: 42px;
				height: 42px;
				// background-color: rgba(255, 255, 255, 0.5);
				background-color: var(--color-primary-blue-green);
			}
			.hand {
				position: relative;
				left: 50%;
				top: 55%;
				font-size: 38px;
				font-style: normal;
				font-weight: 600;
				line-height: 100%;
				color: white;
				font-family: "Miwo", sans-serif;
				user-select: none;
			}
		}
	}
}

.root {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1050;
	opacity: 1;
	pointer-events: all;

	transition: opacity 150ms ease-in-out;
	&[data-open="false"] {
		opacity: 0;
		pointer-events: none;
	}

	.background {
		position: fixed;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000000;
		opacity: 0.5;
	}

	.modal {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 32px 16px;
		width: calc(100% - 32px);
		background-color: var(--color-generic-white);
		display: flex;
		gap: 24px;
		flex-direction: column;

		.title {
			display: flex;
			gap: 16px;
			align-items: center;
			justify-content: space-between;

			&[data-closable="false"] {
				justify-content: center;
			}
		}

		.separator {
			height: 1px;
			width: 100%;
			background-color: var(--color-primary-blue-green);
		}
	}
}

.root {
	display: flex;
	gap: 8px;
	min-width: 0;
	cursor: pointer;
	.image-container {
		> img {
			height: 110px;
			width: 110px;
			object-fit: contain;
		}
	}

	.info-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1;
		min-width: 0;

		.top-infos {
			display: flex;
			flex-direction: column;
			gap: 8px;
			flex: 1;
			min-width: 0;

			.name-container {
				display: flex;
				min-width: 0;
				.name-text {
					overflow: hidden;
					width: 100%;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
			.prices-container {
				display: flex;
				gap: 16px;
				align-items: flex-end;
			}

			.description-container {
				margin-top: 8px;
				.description {
					display: flex;
					align-items: center;
					gap: 8px;
					text-decoration: underline;
				}
			}
		}
	}
}

.root {
	background-image: url("./background.png");
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	min-width: 100vw;
	overflow: hidden;

	.progress-bar {
		position: absolute;
		bottom: 0px;
	}

	.points-container {
		position: fixed;
		z-index: 2;
		top: 0;
		left: 0;
		width: 0;
		height: 0;

		label {
			height: 100%;
		}
	}

	.lang_switcher {
		position: fixed;
		top: 16px;
		right: 16px;
		z-index: 2;
	}

	.loader {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
	}

	.facing_mode_switcher {
		position: fixed;
		top: 16px;
		left: 16px;
		z-index: 2;
	}

	.tests-container {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2;

		label {
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}
}

.root {
	display: flex;
	padding: 8px 16px;
	border: 1px solid black;
	width: fit-content;
	gap: 22px;
	user-select: none;
	justify-content: space-evenly;
	align-items: center;
	&[data-fullwidth="true"] {
		width: 100%;
	}

	.pointer {
		cursor: pointer;
	}
}

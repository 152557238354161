.root {
	max-width: 100%;
	overflow: hidden;

	.scrollable {
		overflow-x: auto;
		white-space: nowrap;
		// That keeps the scrollbar from overlapping the content
		padding-bottom: 20px;
		scroll-snap-type: x mandatory;
		-webkit-overflow-scrolling: touch;
		&::-webkit-scrollbar {
			height: 2px;
			margin: 0 16px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
			border-top: 0.5px solid transparent;
			border-right: 0.5px solid transparent;
			background-clip: padding-box;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: var(--color-primary-blue-green);
			border-radius: 16px;
			height: 1px;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: var(--color-primary-blue-green);
		}

		.item-container {
			cursor: pointer;
			scroll-snap-align: center;
			display: inline-flex;
			margin: 0 8px;
			padding: 8px;
			//border: 1px solid transparent;
			max-width: 200px;
			min-width: 100px;
			width: 25vw;

			flex-direction: column;
			border-radius: 8px;
			border: 1px solid transparent;

			&[data-selected="true"] {
				border: 1px solid var(--color-neutral-grey-strong, #757373);
			}

			.item-image {
				width: 100%;
				padding-bottom: 100%;
				max-height: 200px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
			}
			.item-color {
				text-align: center;
			}
		}
	}
}

.root {
	min-height: 100vh;
	padding: 16px 16px 100px 16px;
	max-width: 1024px;
	margin: auto;
	overflow-x: hidden;
	
	.searchbar {
		margin-top: 16px;
	}

	.history {
		margin-top: 24px;
		display: flex;
		flex-direction: column;
		gap: 24px;

		.item {
			.separator {
				height: 1px;
				width: 100%;
				background-color: var(--color-primary-blue-green);
				margin-top: 24px;
			}
		}

		.empty-container {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 16px;
			align-items: center;
			text-align: center;
			padding: 0 32px;
			padding-top: 7vh;
			position: relative;
			.button-scan {
				left: 16px;
				right: 16px;
				position: fixed;
				bottom: 100px;
			}
		}

		.not-found-container {
			text-align: center;
		}
	}
}

.root {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0px;
	width: 47px;
	height: 47px;
	cursor: pointer;
	font-size: 12px;
	gap: 6px;
	color: var(--color-generic-black);

	.icon {
		position: relative;
		.number {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 12px;
			height: 12px;
			font-size: 8px;
			font-weight: 400;
			line-height: 8px;
			border-radius: 360px;
			position: absolute;
			background-color: var(--color-alert-red);
			color: var(--color-generic-white);

			top: -3px;
			right: -5px;
		}
	}
}

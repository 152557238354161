@import "../../../themes/constants.module.scss";

.root {
	display: flex;
	flex-direction: column;
	gap: 8px;
	min-width: 0;
	cursor: pointer;
	.image-container {
		margin: auto;
		width: 100%;
		padding-bottom: 100%;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

	.subitems-container {
		display: flex;
		gap: 4px;
		justify-content: flex-start;
		.subitem-image-container {
			width: 33%;
			padding-bottom: 33%;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
		}
	}

	.info-container {
		display: flex;
		flex-direction: column;
		flex: 1;
		min-width: 0;

		.name-container {
			display: flex;
			min-width: 0;
			.name-text {
				overflow: hidden;
				width: 100%;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		.prices-container {
			margin-top: 8px;
		}

		.description-container {
			margin-top: 8px;
			.description {
				display: flex;
				align-items: center;
				gap: 8px;
				text-decoration: underline;
			}
		}
	}
}

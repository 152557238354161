.root {
	display: flex;
	gap: 8px;
	min-width: 0;
	.image-container {
		> img {
			height: 110px;
			width: 110px;
			object-fit: contain;
		}
	}

	.info-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1;
		min-width: 0;

		.top-infos {
			display: flex;
			flex-direction: column;
			gap: 8px;
			flex: 1;
			min-width: 0;

			.name-container {
				display: flex;
				min-width: 0;
				.name-text {
					overflow: hidden;
					width: 100%;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}
		.quantity-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

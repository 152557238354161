.root {
	&[data-opened="true"] {
		.drawer {
			transform: translateY(0);
		}

		.background-container {
			pointer-events: all;
			opacity: 1;
		}

		.cross-container {
			transform: translateY(0);
		}
	}

	.background-container {
		pointer-events: none;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(1, 1, 1, 0.5);
		opacity: 0;
		transition: opacity 250ms ease-in-out;
	}

	.cross-container {
		position: fixed;
		z-index: 1021;
		top: 8px;
		right: 8px;
		transform: translateY(110vh);
		transition: transform 250ms ease-in-out;
	}

	.drawer {
		position: fixed;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;

		transform: translateY(110%);
		transition: transform 250ms ease-in-out;
		height: 100%;
		background-color: var(--color-generic-white);
		overflow: scroll;
		z-index: 1020;

		max-width: 800px;
		margin: auto;
		.prices-container {
			display: flex;
			align-items: flex-end;
			gap: 16px;
			padding: 0 16px;
			margin-top: 24px;
			.price {
				display: flex;
				align-items: flex-end;
				gap: 4px;
			}
		}

		.brand {
			padding: 0 16px;
			margin-top: 16px;
		}

		.name {
			padding: 0 16px;
			margin-top: 8px;
		}

		.declinations {
			padding: 0 16px;
			margin-top: 16px;
		}

		.declinations-container {
			margin-top: 16px;
		}

		.tabs-container {
			padding: 0 16px;
			margin-top: 24px;
		}

		.tab-content {
			padding: 0 16px;
			margin-top: 32px;
			padding-bottom: 30px;

			.tab-description,
			.tab-description > * {
				color: var(--color-generic-black);
				/* text-lg/regular */
				font-family: Roboto;
				font-size: 18px !important;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: -0.36px;
			}
		}

		.drawer-footer {
			z-index: 5;
			position: sticky;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			flex-direction: column;
			gap: 16px;

			padding: 16px;

			background-color: var(--color-generic-white);

			box-shadow: 0px -4px 12px 0px rgba(0, 0, 0, 0.09);
		}
	}
}

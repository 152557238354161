.root {
	--position-x: 0;
	--animation-delay: 250ms;
	position: relative;
	background-color: rgba(255, 255, 255, 0.6);
	border-radius: 360px;
	padding: 8px;
	backdrop-filter: blur(9px);

	max-width: 600px;
	margin: auto;
	.links {
		position: relative;
		z-index: 10;
		display: flex;
		width: 100%;
		justify-content: space-between;
	}

	.cursor {
		position: absolute;
		width: 80px;
		height: 100%;
		pointer-events: none;
		transform: translateX(var(--position-x));
		transition: transform var(--animation-delay) ease-in-out;
		border-radius: 360px;
		overflow: hidden;

		> div {
			background-color: var(--color-neutral-grey-soft);

			height: 100%;
		}
	}
}

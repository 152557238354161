.root {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	// Temporary styling
	background-color: rgba(0, 0, 0, 0.4);

	.tooltip {
		cursor: pointer;
		flex-direction: column;

		justify-content: center;
		align-items: center;

		display: flex;
		pointer-events: all;

		&:after {
			display: block;
		}
		/* Object container styling */
		.description-container {
			padding: 16px;
			border-radius: 8px;
			background-color: white;
			gap: 8px;
			flex-direction: column;
			width: 290px;
			z-index: 3;
			height: 100px;

			/* Title styling inside object container */
			.title {
				text-overflow: ellipsis;
				max-height: 32px;
				overflow: hidden;
				width: 267px;
			}

			/* Description styling inside object container */
			.description {
				display: flex;
				align-items: center;
				gap: 8px;
				text-decoration: underline;
			}
		}
	}
}

.root {
	.label {
		display: block;
		border-radius: 360px;
		background-color: var(--color-neutral-grey-soft);
		color: var(--color-generic-black);
		padding: 8px;
		cursor: pointer;
		white-space: nowrap;
	}

	.radio-button:checked + .label {
		background-color: var(--color-primary-blue-green);

		.label-typo {
			color: var(--color-generic-white);
		}
	}

	.radio-button {
		display: none;
	}
}

@import "themes/constants.module.scss";

body {
    --color-transparent-1: #{$color-transparent-1};
    --color-transparent-2: #{$color-transparent-2};
    --color-transparent-3: #{$color-transparent-3};

    --color-generic-white: #{$color-generic-white};
    --color-generic-black: #{$color-generic-black};

    --color-primary-blue-green: #{$color-primary-blue-green};
    --color-neutral-grey-soft: #{$color-neutral-grey-soft};
    --color-neutral-grey-strong: #{$color-neutral-grey-strong};
    --color-alert-green: #{$color-alert-green};
    --color-alert-red: #{$color-alert-red};
}

[theme-mode] {
    --color-text-1: #{$color-generic-black};
    --color-text-2: #{$color-generic-white};
    --color-primary-1: #{$color-generic-white};
    --color-primary-2: #{$color-generic-black};
    --color-background-1: #{$color-generic-white};
    --color-transparent-1: #{$color-transparent-1};
    --color-transparent-2: #{$color-transparent-2};
    --color-transparent-3: #{$color-transparent-3};
}

@import "../../../themes/constants.module.scss";
.root {
	max-width: 1024px;
	margin: auto;
	padding: 16px 0 100px 0;
	overflow-x: hidden;

	.title {
		padding: 0 16px;
	}

	.searchbar {
		padding: 0 16px;
		margin-top: 16px;
	}

	.tags-container {
		margin-top: 16px;
		padding: 0 16px 16px 16px;
		display: flex;
		gap: 16px;

		&::-webkit-scrollbar {
			display: none;
			-webkit-appearance: none;
		}
		max-width: 100%;
		overflow: auto;
	}

	.items {
		padding: 0 16px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 24px;

		@media (max-width: $screen-sm) {
			grid-template-columns: repeat(2, 1fr);
		}

		.item {
			.separator {
				height: 1px;
				width: 100%;
				background-color: var(--color-primary-blue-green);
				margin-top: 24px;
			}
		}

		.empty-container {
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 16px;
			align-items: center;
			text-align: center;
			padding: 0 32px;
			padding-top: 7vh;
			position: relative;
			.button-scan {
				left: 16px;
				right: 16px;
				position: fixed;
				bottom: 100px;
			}
		}

		.not-found-container {
			text-align: center;
		}
	}
}

.root {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	&[data-animation="true"] {
		animation: scaleUpAndDown 1200ms ease-in-out infinite;
	}

	.svg-indicator {
		width: 128px;
		height: 128px;

		transform: rotate(270deg);
	}

	.svg-indicator-track {
		stroke: #ddd;
	}

	.svg-indicator-indication {
		stroke: white;
		stroke-dasharray: 352.2px;
	}

	.logo {
		position: absolute;
		top: 48%;
		left: 50%;
		transform: translate(-50%, -52%);
	}
}

@keyframes scaleUpAndDown {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}

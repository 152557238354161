.root {
	vertical-align: center;
	color: var(--color-generic-black);

	&.display-small {
		font-family: Roboto;
		font-size: 32px;
		font-style: normal;
		font-weight: 600;
		line-height: 100%; /* 32px */
	}

	&.display-xsmall {
		font-family: Roboto;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 100%;
	}

	&.text-lg-semibold {
		font-family: Roboto;
		font-size: 18px;
		font-style: normal;
		font-weight: bold;
		line-height: normal;
		letter-spacing: -0.36px;
	}

	&.text-lg-semibold {
		font-family: Roboto;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: -0.36px;
	}

	&.text-lg-medium {
		font-family: Roboto;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: -0.36px;
	}

	&.text-lg-regular {
		font-family: Roboto;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: -0.36px;
	}

	&.text-md-bold {
		font-family: Roboto;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 100%; /* 16px */
		letter-spacing: -0.32px;
	}

	&.text-md-semibold {
		font-family: Roboto;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 100%; /* 16px */
		letter-spacing: -0.32px;
	}

	&.text-md-medium {
		font-family: Roboto;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%; /* 16px */
		letter-spacing: -0.32px;
	}

	&.text-md-regular {
		font-family: Roboto;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 16px */
		letter-spacing: -0.32px;
	}

	&.text-sm-semibold {
		font-family: Roboto;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 100%; /* 14px */
	}

	&.text-sm-medium {
		font-family: Roboto;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 100%; /* 14px */
	}

	&.text-sm-regular {
		font-family: Roboto;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 14px */
	}

	&.text-xs-regular {
		font-family: Roboto;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 12px */
	}
}

.root {
	min-width: 100%;
	.container {
		min-height: 100vh;
		background-image: url("./background.png");
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;

		.text-container {
			max-width: 450px;
			background-color: var(--color-generic-white);
			text-align: center;
			padding: 32px 16px;
			border-radius: 8px;

			.title {
				display: flex;
				gap: 24px;
				flex-direction: column;
				margin-top: 24px;
				.separator {
					height: 1px;
					width: 100%;
					background-color: var(--color-primary-blue-green);
				}
			}
		}
	}
}

.modal-buttons-container {
	display: flex;
	flex-direction: column;
	gap: 8px;

	.logo-mid {
		width: 200px;
		margin: auto;
	}

	.loader-container {
		margin-top: 24px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
	}
}
